.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.outlineCss {
  overflow-y: scroll !important;
  height: 88vh !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pagePane {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  border-style: solid;
  border-color: snow;
  height: 90vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.zbtn {
  color: #03409c;
  font-size: xx-large;
  background: #fff;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.3s ease;
}
.zbtn:hover {
  background: #eef;
  color: #00d9ff;
  /* font-size: xxx-large; */
}

.yemen-navlink {
  color: white !important;
  font-size: 4vh !important;
}

.navbar-yemen {
  background-color: #fb3640;
  color: white !important;
  border-bottom: solid;
  border-bottom-color: #929292;
  border-bottom-width: 10px;
  padding: 0 !important;
}

.yemen-navlink:hover {
  font-weight: bold;
}

.yemen-nav-brand {
  font-size: 5vh !important;
  color: white !important;
}

#root,
.main {
  background-color: #fbf8ea;
  font-family: lateef;
}

html,
body {
  background-color: #fbf8ea;
}

.yemen-text {
  color: #395fb5;
}

form div label {
  font-size: 4vh;
}

form div input {
  font-size: 3vh !important;
}

form div textarea {
  font-size: 2vh !important;
}

h2 button {
  color: #395fb5 !important;
  font-size: 5vh !important;
  background-color: #fbf8ea !important;
}

.AccordianItem {
  border: solid !important;
  border-color: rgba(192, 189, 189, 0.867) !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}


.issueNumber{
color: #fb3640;
width: max-content;
font-size: 1.9vh;
text-decoration: none;
}

@media only screen and (max-width: 992px) {

  .hideOnMobile{
    display: none;
  }
  
  .navbar-yemen {
    border-bottom-width: 1vh;
  }

  form div label {
    font-size: 4vh;
  }

  .prev{
    position: absolute;
    right: 1vh
  }
  
  
  .next{
    position: absolute;
    left: 1vh
  }

   
}

@media only screen and (min-width: 992px) {



  .TOCButton {
    display: none; 
  }
}


.rpv-bookmark__title{
  color: #fb3640;
  font-size: 2vh;
}

rpv-bookmark__item {
  
  border-bottom: thin;
  border-bottom-style: solid;
  border-bottom-color: white;

}

.blinking {
  animation: blinkingText 5s infinite;
}
@keyframes blinkingText {
  0% {
    color: #fb3640;
  }
  25% {
    color: #395fb5;
  }
  50% {
    color: #fb3640;
  }
  75% {
    color: #fbf8ea;
  }
  100% {
    color: #fb3640;
  }
}

.TOCButton{
  padding-left: 1vh;
  padding-right: 1vh;
  color: #395fb5;
  Text-decoration: underline;
}

.introBody {
  font-size: 3vh;
  text-align: justify;
}

.sign {
  color: #395fb5;
  border-bottom: solid;
  border-bottom-color: #fb3640;
  text-align: -webkit-left;
}

.menu {
  width: 50%;
  height: 85%;
  background-color :#fb3640;
  position: fixed; 
  left: 100%;
  transition-duration: 1.2s;
  overflow: scroll;
  z-index: 1000;
  transition-property: all;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  padding-top: 5%;
}

.show
{
  background-color: rgb(238, 238, 238);
  left: 50%;
}

